import { Plugins } from "@capacitor/core"
import axios from "axios"

const { Storage } = Plugins

const state = () => ({
  currentCart: {
    n_items: 0,
    tot_price: 0,
    has_promo: false,
    discount: 0,
    items: [],
    payment_condition: "",
    customer: {
      name: "",
      surname: "",
      azienda: "",
      id: 0,
      piva: "",
      citta: "",
      cap: "",
    },
  },
})

const getters = {
  getCurrentCart(state) {
    return state.currentCart
  },
}

const actions = {
  addItem({ commit }, payload) {
    commit("addItemToCart", payload)
  },
  removeItem({ commit }, payload) {
    commit("removeItemFromCart", payload)
  },
  substractOne({ commit }, payload) {
    commit("substractOneFromItem", payload)
  },
  setCustomer({ commit }, payload) {
    commit("addCustomerToCart", payload)
  },
  resetCart({ commit }) {
    commit("resetAllCart")
  },
}

const mutations = {
  addItemToCart(state, payload) {
    // check if is there already an item like payload in the cart
    if (
      !state.currentCart.items.some((element) => element.name == payload.name)
    ) {
      // if not pushes payload in the cart
      state.currentCart.items.push(payload)
    } else {
      //if yes look for index of the item already present in the cart
      let index = state.currentCart.items.findIndex(
        (element) => element.name == payload.name
      )
      //then aument by 1 the item.number
      state.currentCart.items[index].number++
    }

    state.currentCart.n_items++

    state.currentCart.tot_price = 0
    state.currentCart.items.forEach((item) => {
      state.currentCart.tot_price += item.number * item.price
    })

    if (state.currentCart.n_items >= 5) {
      state.currentCart.has_promo = true

      state.currentCart.discount = state.currentCart.tot_price * 0.1
      state.currentCart.tot_price -= state.currentCart.discount
    } else {
      state.currentCart.has_promo = false
      state.currentCart.discount = 0
    }
  },
  removeItemFromCart(state, payload) {
    if (
      !state.currentCart.items.some((element) => element.name == payload.name)
    ) {
      return
    } else {
      if (state.currentCart.has_promo) {
        let index = state.currentCart.items.findIndex(
          (element) => element.name == payload.name
        )
        state.currentCart.items.splice(index, 1)

        state.currentCart.n_items -= payload.number

        if (state.currentCart.n_items >= 5) {
          state.currentCart.has_promo = true
          state.currentCart.discount = state.currentCart.tot_price * 0.1
          state.currentCart.tot_price -= payload.number * payload.price
        } else {
          state.currentCart.has_promo = false
          state.currentCart.discount = 0
          state.currentCart.tot_price =
            state.currentCart.tot_price / 0.9 - payload.number * payload.price
        }
      } else {
        let index = state.currentCart.items.findIndex(
          (element) => element.name == payload.name
        )
        state.currentCart.items.splice(index, 1)

        state.currentCart.n_items -= payload.number

        state.currentCart.tot_price =
          state.currentCart.tot_price - payload.number * payload.price
      }
    }
  },
  substractOneFromItem(state, payload) {
    if (
      !state.currentCart.items.some((element) => element.name == payload.name)
    ) {
      return
    } else {
      let index = state.currentCart.items.findIndex(
        (element) => element.name == payload.name
      )

      if (state.currentCart.items[index].number - 1 <= 0) {
        state.currentCart.items.splice(index, 1)
      } else {
        state.currentCart.items[index].number--
      }

      state.currentCart.n_items--

      state.currentCart.tot_price = 0
      state.currentCart.items.forEach((item) => {
        state.currentCart.tot_price += item.number * item.price
      })

      if (state.currentCart.n_items >= 5) {
        state.currentCart.has_promo = true

        state.currentCart.discount = state.currentCart.tot_price * 0.1
        state.currentCart.tot_price -= state.currentCart.discount
      } else {
        state.currentCart.has_promo = false
        state.currentCart.discount = 0
      }
    }
  },
  addCustomerToCart(state, payload) {
    if (state.currentCart.customer.ragioneSociale != payload.ragioneSociale) {
      state.currentCart = {
        n_items: 0,
        tot_price: 0,
        has_promo: false,
        discount: 0,
        items: [],
        payment_condition: "",
        customer: {
          id: 0,
          idTipoAnagrafica: 0,
          idUtente: 0,
          idPadre: 0,
          nome: "",
          cognome: "",
          ragioneSociale: "",
          partitaIva: "",
          codiceFiscale: "",
          email: "",
          telefono: "",
          cellulare: "",
          indirizzo: "",
          citta: "",
          provincia: "",
          regione: "",
          nazione: "",
          note: "",
          anagraficaYoube: true,
        },
      }

      state.currentCart.customer.nome = payload.nome
      state.currentCart.customer.cognome = payload.cognome
      state.currentCart.customer.ragioneSociale = payload.ragioneSociale
      state.currentCart.customer.id = payload.id
      state.currentCart.customer.partitaIva = payload.partitaIva
      state.currentCart.customer.citta = payload.citta
    }
    return
  },
  resetAllCart(state) {
    console.log("resetta carrello store")
    state.currentCart = {
      n_items: 0,
      tot_price: 0,
      has_promo: false,
      discount: 0,
      items: [],
      payment_condition: "",
      customer: {
        id: 0,
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        indirizzo: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
      },
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

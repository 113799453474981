import { Plugins } from "@capacitor/core"

const { Storage } = Plugins

const state = () => ({
  learning: {
    currentChapterTitle: "",
    currentLearningId: 0,
  },
})

const getters = {
  getCurrentChapterTitle(state) {
    return state.learning.currentChapterTitle
  },
  getCurrentLearningId(state) {
    return state.learning.currentLearningId
  },
}

const actions = {
  setCurrentChapterTitle({ commit }, payload) {
    commit("setCurrentChapterTitle", payload)
  },
  setCurrentLearningId({ commit }, payload) {
    commit("setCurrentLearningId", payload)
  },
}

const mutations = {
  setCurrentChapterTitle(state, payload) {
    state.learning.currentChapterTitle = payload
  },
  setCurrentLearningId(state, payload) {
    state.learning.currentLearningId = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

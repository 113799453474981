import { createRouter, createWebHistory } from "@ionic/vue-router"
// import Tabs from '../components/Tabs.vue'
import { setI18nLanguage, loadLocaleMessages } from "./../i18n"

import store from "../store/index"
import axios from "axios"
import { API_MOCK_ENABLE, API_MOCK_PATH, API_URL } from "../common/config"

export function setupRouter(app, i18n) {
  // const locale =
  //     i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value
  window.i18n = i18n

  const routes = [
    {
      path: "/",
      redirect: "/welcome",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/conferma-codice-due-fattori/:username/:password",
      name: "ConfermaDueFattori",
      component: () => import("@/views/ConfermaCodiceDueFattori.vue"),
    },
    {
      path: "/feed",
      name: "feed",
      component: () => import("@/views/Feed.vue"),
    },
    // {
    //   path: '/home',
    //   component: Tabs,
    //   children: [
    //     {
    //       path: '',
    //       name: "home",
    //       component: () => import('@/views/Home.vue')
    //     }
    //   ]
    // },
    {
      path: "/welcome",
      name: "welcome",
      component: () => import("@/views/Welcome.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: () => import("@/views/Campaigns.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/kpi/:id",
      name: "campaign_kpi",
      component: () => import("@/views/Campaign/Kpi.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/award/:id",
      name: "campaign_award",
      component: () => import("@/views/Campaign/Award.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/badge/:id",
      name: "campaign_badge",
      component: () => import("@/views/Campaign/Badge.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/ranking/:id",
      name: "campaign_ranking",
      component: () => import("@/views/Campaign/Ranking.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/mission/:id",
      name: "campaign_mission",
      component: () => import("@/views/Campaign/Mission.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/menu/:id",
      name: "campaign_menu",
      component: () => import("@/views/Campaign/Menu.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/social/:id",
      name: "campaign_social",
      component: () => import("@/views/Campaign/Social.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/social/post/:id/:type/:postid/:code",
      name: "campaign_social_post",
      component: () => import("@/views/Campaign/SocialPost.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/learning/:id/:title/:parent?",
      name: "campaign_learning",
      component: () => import("@/views/Campaign/Learning.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/campaign/quiz/chapter/:chapter/:id/:title/:parentId/:parent/:parentTitle",
      name: "campaign_quiz",
      component: () => import("@/views/Campaign/Quiz.vue"),
      props: (parentParams) => ({
        parentParams,
      }),
      meta: { requiredAuth: true },
    },
    {
      path: "/account",
      name: "account",
      component: () => import("@/views/Account/Account.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/Faq.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/tutorial",
      name: "tutorial",
      component: () => import("@/views/Tutorial.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/testing",
      name: "testing",
      component: () => import("@/views/Testing.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/report",
      name: "report",
      component: () => import("@/views/Report/Report.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/social-login",
      name: "social_login",
      component: () => import("@/views/SocialLogin.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/catalogo",
      name: "catalogo",
      component: () => import("@/views/Catalogo/Catalogo.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/lista-ordini",
      name: "lista-ordini",
      component: () => import("@/views/Catalogo/ListaOrdini.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/catalogo-con-filtro",
      name: "catalogoConFiltro",
      component: () => import("@/views/Catalogo/CatalogoConFiltroClienti.vue"),
      meta: { requiredAuth: true },
    },

    {
      path: "/yoube-catalogo",
      name: "yoube-catalogo",
      component: () => import("@/views/Catalogo/YoubeCatalogo.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/shopping-cart",
      name: "ShoppingCart",
      component: () => import("@/views/Catalogo/ShoppingCart.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/conferma-ordine",
      name: "ConfermaOrdine",
      component: () => import("@/views/Catalogo/ConfermaOrdine.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/invia-ordine/:idOrder",
      name: "InviaOrdine",
      component: () => import("@/views/Catalogo/InviaOrdine.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/yoube-riepilogo-ordini",
      name: "yoube riepilogo ordini",
      component: () => import("@/views/Catalogo/YoubeRiepilogoOrdini.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/anagraficaClienti",
      name: "anagraficaClienti",
      component: () => import("@/views/AnagraficaClienti.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/dettagli-cliente/:idCliente",
      name: "DettagliCliente",
      component: () => import("@/views/Anagrafiche/DettagliCliente.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/anagraficaClientiCaldi",
      name: "anagraficaClientiCaldi",
      component: () => import("@/views/AnagraficaClientiCaldi.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/anagraficaLeadCaldi",
      name: "anagraficaLeadCaldi",
      component: () => import("@/views/AnagraficaLeadCaldi.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/anagraficaClientiAssegnati",
      name: "anagraficaClientiAssegnati",
      component: () => import("@/views/AnagraficaClientiAssegnati.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/anagraficaFarmacieAssegnate",
      name: "anagraficaFarmacieAssegnate",
      component: () => import("@/views/AnagraficaFarmacieAssegnate.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/QuizOneToMany",
      name: "QuizOneToMany",
      component: () => import("@/views/QuizOneToMany.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/EvalOneToMany",
      name: "EvalOneToMany",
      component: () => import("@/views/EvalOneToMany.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/MappaDeiTalenti",
      name: "MappaDeiTalenti",
      component: () => import("@/views/AnalisiDeiTalenti/MappaDeiTalenti.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/ProveEmmanuele",
      name: "ProveEmmanuele",
      component: () => import("@/views/ProveEmmanuele.vue"),
      meta: { requiredAuth: true },
    },

    {
      path: "/AnalisiDelTalento",
      name: "AnalisiDelTalento",
      component: () =>
        import("@/views/AnalisiDeiTalenti/AnalisiDelTalento.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/RisultatiAnalisiTalenti",
      name: "RisultatiAnalisiTalenti",
      component: () =>
        import("@/views/AnalisiDeiTalenti/RisultatiAnalisiTalenti.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/InductionPlan",
      name: "InductionPlan",
      component: () => import("@/views/InductionPlan.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/ContinousImprovement",
      name: "ContinousImprovement",
      component: () => import("@/views/ContinousImprovement.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/QuizOnboarding",
      name: "QuizOnboarding",
      component: () => import("@/views/QuizOnboarding.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/changepassword",
      name: "ChangePassword",
      component: () => import("@/views/ChangePassword.vue"),
      // meta: { requiredAuth: true },
    },
    {
      path: "/confermacambiopassword",
      name: "ConfermaCambioPassword",
      component: () => import("@/views/ConfermaCambioPassword.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/kpiChat",
      name: "kpiChat",
      component: () => import("@/views/Campaign/KpiChat.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/debug/:idCampaign/:idUser/:campaignModule/:titleCampaign",
      name: "Debug",
      component: () => import("@/views/Debug.vue"),
      meta: { requiredAuth: true },
    },
    {
      path: "/axerta/inizia-rilevazione/:idOrdine/",
      name: "IniziaRilevazione",
      meta: { requiredAuth: true },
      component: () => import("@/views/Axerta/IniziaRilevazione.vue"),
    },
    {
      path: "/axerta/finisci-rilevazione/:idOrdine/:idRilevazione",
      name: "FinisciRilevazione",
      meta: { requiredAuth: true },
      component: () => import("@/views/Axerta/FinisciRilevazione.vue"),
    },
    {
      path: "/axerta/lista-ordini",
      name: "ListaOrdini",
      meta: { requiredAuth: true },
      component: () => import("@/views/Axerta/ListaOrdini.vue"),
    },
    {
      path: "/axerta/descrizione-ordine/:idOrdine",
      name: "DescrizioneOrdine",
      meta: { requiredAuth: true },
      component: () => import("@/views/Axerta/DescrizioneOrdine.vue"),
    },

    {
      path: "/axerta/nuova-rilevazione/:idOrdine/:idIntervento/:inizioIntervento/:fineIntervento",
      name: "NuovaRilevazione",
      props: true,
      meta: { requiredAuth: true },
      component: () => import("@/views/Axerta/NuovaRilevazione.vue"),
    },
    {
      path: "/axerta/elenco-rilevazioni/:idOrdine/:idIntervento/:inizioIntervento/:fineIntervento",
      name: "ElencoRilevazioni",
      props: true,
      meta: { requiredAuth: true },
      component: () => import("@/views/Axerta/ElencoRilevazioni.vue"),
    },
    {
      path: "/perform-logout",
      name: "PerformLogout",
      component: () => import("@/views/PerformLogout.vue"),
    },
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  })

  // navigation guards

  function guard(to, from, next, authData) {
    if (to.meta && to.meta.requiredAuth == true) {
      if (to.path.slice(0, 6) == "/debug") {
        return next()
      }
      if (authData.token) {
        // if (authData.token == "OK" && to.name != "ConfermaDueFattori") {
        //   return next("/perform-logout")
        // } else {
        return next()
        // }
      }
      return next({ path: "/login" })
    } else {
      return next()
    }
  }

  router.beforeEach((to, from, next) => {
    let authData = store.getters["auth/getAuthData"]
    if (!authData.token) {
      store.dispatch("auth/loadStorageTokens").then(
        () => {
          authData = store.getters["auth/getAuthData"]
          return guard(to, from, next, authData)
        },
        (error) => {
          console.log(error)
          return guard(to, from, next, authData)
        }
      )
    } else {
      return guard(to, from, next, authData)
    }
  })

  router.beforeEach(async (to, from, next) => {
    // const paramsLocale = to.params.locale

    // load app settings
    if (!app.config.globalProperties.settings) {
      let res = await axios({
        // url: API_MOCK_PATH + 'setting.json',
        url: API_MOCK_ENABLE
          ? API_MOCK_PATH + "setting.json"
          : API_URL + "Setting",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (res.status == 200) {
        console.log("res.data.ragione_sociale", res.data.ragione_sociale)
        app.config.globalProperties.settings = res.data
        // if(!app.config.globalProperties.settings.facebookToken){
        //
        // }
      }
    }

    const paramsLocale =
      app.config.globalProperties.settings.enabled_languages.find((e) => {
        return e.ID == app.config.globalProperties.settings.default_language
      }).text

    // const paramsLocale = 'it';
    // use locale if paramsLocale is not in SUPPORT_LOCALES
    /*if (!SUPPORT_LOCALES.includes(paramsLocale)) {
          return next(`/${locale}`)
        }*/

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale)
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale)

    return next()
  })

  return router
}

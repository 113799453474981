<script>

import {externalLinkBlank} from "../helper.auth";
import {alertController} from "@ionic/vue";
import moment from "moment";

export default {
  name: 'UtilService',
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    externalLinkBlank(value) {
      return externalLinkBlank(value);
    },
    async simplePrompt(title,text,closetext){
      const alert = await alertController.create({
        cssClass: "ion-alert-big",
        header: title,
        message: text,
        buttons: [
          {
            text: closetext,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      return alert.present();
    }
  }
};
</script>